<template>
  <div
    class="swiper-pagination"
    :class="classes"
  ></div>
</template>
<script lang="ts" setup>
const props = defineProps({
  autoplay: { type: Boolean, default: false }
});

const classes = computed(() => {
  if (props.autoplay) {
    return `uno-omb6lt`;
  }
  return `uno-f6u2pj`;
});

</script>
<style>
@keyframes swiper-pagination-animation {
	0% {
		width: 0;
	}

	100% {
		width: 100%;
	}
}
</style>
